<template>
    <BT-Print
        :buttonClass="null"
        :getFileName="() => 'Delivery Slips.pdf'"
        :hide="hideButton"
        :loadingMsg="loadingMsg"
        :onPullSuccessAsync="pullForOrderSlips"
        :showToggle="showToggle"
        :text="text"
        title="Print">
        <template v-slot="{ item, strategy }">
            <div v-if="item != null">
                <v-container v-for="({ customer, destination, dueDate, invoiceNumber, lines, logoImgData, page, pages, subTotal, supplier, taxTotal }, ind) in item" :key="ind" class="pdf-half-page">
                    <v-row no-gutters>
                        <v-col cols="8">
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <v-img v-if="strategy != 'jspdf'" class="pdf-logo" eager :src="logoImgData" max-width="100px" max-height="100px" />
                                    <!-- <v-img class="pdf-logo" eager src="/img/logo-512x512.png" max-width="100px" max-height="100px" /> -->
                                </v-col>
                                <v-col cols="6">
                                    <v-list-item three-line class="pa-0" dense>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>SUPPLIER</v-list-item-subtitle>
                                            <v-list-item-title><strong>{{ supplier.companyName }}</strong></v-list-item-title>
                                            <v-list-item-subtitle v-if="$BlitzIt.auth.session.company.primaryEmail != null">
                                                {{ $BlitzIt.auth.session.company.primaryEmail }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle v-if="$BlitzIt.auth.session.company.phoneNumber != null">
                                                {{ $BlitzIt.auth.session.company.phoneNumber }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="6">
                                    <v-list-item three-line class="pr-0" dense>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>CUSTOMER</v-list-item-subtitle>
                                            <v-list-item-title><strong>{{ customer.companyName }}</strong></v-list-item-title>
                                            <v-list-item-subtitle>{{ destination | toLocationLineOne }}</v-list-item-subtitle>
                                            <v-list-item-subtitle>{{ destination | toLocationLineTwo }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="4">
                            <v-list-item dense>
                                <v-list-item-content>
                                    <h2>Delivery Slip</h2>
                                    <v-list-item-subtitle>Page {{ page }} of {{ pages }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item dense>
                                <v-list-item-content>
                                    <v-list-item-subtitle>Invoice #</v-list-item-subtitle>
                                    <v-list-item-title>{{ invoiceNumber }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item dense>
                                <v-list-item-content>
                                    <v-list-item-subtitle>Delivery Due</v-list-item-subtitle>
                                    <v-list-item-title>{{ dueDate | toDayShortDate }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12">
                            <v-row class="my-1" dense style="border-bottom: 1px solid lightgray; margin: 5px 10px 0px 0px;">
                                <v-col cols="2">QTY</v-col>
                                <v-col cols="6">DESCRIPTION</v-col>
                                <v-col cols="2">UNIT PRICE</v-col>
                                <v-col cols="2" style="text-align: right;">TOTAL</v-col>
                            </v-row>

                            <v-row dense v-for="(lineItem, index) in lines" :key="index" style="font-size: 13px; margin: 5px 0px 0px 0px;">
                                <v-col cols="2">{{ lineItem.quantity | toDisplayNumber }}</v-col>
                                <v-col cols="6">{{ lineItem.description }}</v-col>
                                <v-col cols="2">{{ lineItem.unitPrice | toCurrency }}</v-col>
                                <v-col cols="2" style="text-align: right;">{{ lineItem.quantity * lineItem.unitPrice | toCurrency }}</v-col>
                            </v-row>

                            <div v-if="page == pages">
                                <v-divider style="margin: 5px 0px;" />
                                <v-row dense style="font-size: 14px; text-align: right;">
                                    <v-col cols="9">Subtotal:</v-col>
                                    <v-col cols="3">{{ subTotal | toCurrency }}</v-col>
                                </v-row>
                                <v-row dense style="font-size: 14px; text-align: right;">
                                    <v-col cols="9">GST:</v-col>
                                    <v-col cols="3">{{ taxTotal | toCurrency }}</v-col>
                                </v-row>
                                <v-row dense style="font-size: 22px; text-align: right;">
                                    <v-col cols="9">Total:</v-col>
                                    <v-col cols="3">{{ subTotal + taxTotal | toCurrency }}</v-col>
                                </v-row>
                            </div>
                            <div v-else>
                                <v-divider style="margin: 5px 0px;" />
                                <v-row dense style="font-size: 14px; text-align: right;">
                                    <v-col cols="12">More On Page {{ page + 1 }}</v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </template>
    </BT-Print>
</template>

<script>
import { firstBy } from 'thenby';
import { getImageData } from '~helpers';

export default {
    name: 'BT-Print-Order-Slips',
    components: {
        BTPrint: () => import('~components/BT-Print.vue')
    },
    data: function() {
        return {
            loadingMsg: null
        }
    },
    props: {
        hideButton: {
            type: Boolean,
            default: false
        },
        movements: {
            type: Array,
            default: null
        },
        selectConsignments: {
            type: Boolean,
            default: false
        },
        selectMovements: {
            type: Boolean,
            default: false
        },
        showToggle: {
            type: Boolean,
            default: false
        },
        stockConsignments: {
            type: Array,
            default: null
        },
        text: {
            type: String,
            default: null
        },
        useConsignments: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        async pullForOrderSlips() {
            var allMovementConsignmentIDs = [];
            var allConsignmentIDs = [];
            var conIDs = [];
            var fMovements = this.copyDeep(this.movements);

            //collect consignmentIDs
            if (this.isLengthyArray(this.stockConsignments)) {
                allConsignmentIDs = this.stockConsignments.map(x => x.id);
            }
            else if (this.isLengthyArray(fMovements)) {
                if (this.selectMovements) {
                    fMovements = await this.$selectItemsFrom({
                        items: fMovements,
                        itemText: 'destinationLocation.companyAccount.companyName',
                        label: 'Deliveries',
                        subTextFunction: item => item.dueArrivalOn,
                        subTextFilter: 'toDayShortDate'
                    });
                }

                if (!this.isLengthyArray(fMovements)) {
                    return [];
                }

                this.loadingMsg = 'Pulling deliveries';
                var mRes = await this.$BlitzIt.api.get('deliveries', '/get/GetConsignmentIDs?id=' + fMovements.map(x => x.id).toString(), null, null);
                allMovementConsignmentIDs = mRes.data.data;
                allConsignmentIDs = allMovementConsignmentIDs.flatMap(x => x.value);
            }
            else {
                return [];
            }

            var excessIDs = this.copyDeep(allConsignmentIDs);
            var iter = 0;
            var customerOrders = [];
            var consignments = this.stockConsignments ?? [];

            try {
                if (this.isLengthyArray(excessIDs)) {
                    //pull in sets of 20
                    do {
                        conIDs = excessIDs.slice(0, excessIDs.length > 20 ? 20 : excessIDs.length);
                        iter += conIDs.length;
                        this.loadingMsg = `${iter} of ${allConsignmentIDs.length} orders`;
                        customerOrders.push(...this.copyDeep(await this.$BlitzIt.store.getAll('customer-orders', { ids: conIDs.toString(), includeDetails: true }, true)));
                        excessIDs = excessIDs.filter(x => !conIDs.some(y => y == x));
                    } while (this.isLengthyArray(excessIDs))
                }
                console.log(customerOrders);
                customerOrders.forEach(order => {
                    order.orderItems.sort(firstBy(z => z.sortNumber));
                })
            }
            catch (err) {
                console.log(this.extractErrorDescription(err));
            }
            finally {
                this.loadingMsg = null;
            }

            var rList = allConsignmentIDs.map(consignmentID => {
                //find delivery
                var mID = allMovementConsignmentIDs.find(x => x.value.some(v => v == consignmentID));
                var delivery = null;
                if (mID != null) {
                    delivery = fMovements.find(m => m.id == mID.key);
                }

                return {
                    consignmentID: consignmentID,
                    logoImgData: null,
                    order: customerOrders.find(x => x.id == consignmentID),
                    delivery: delivery,
                    stockConsignment: consignments.find(x => x.id == consignmentID)
                };
            });

            if (this.selectConsignments) {
                rList = await this.$selectItemsFrom({
                    items: rList,
                    itemText: 'stockConsignment.buyer.companyName',
                    label: 'Consignments',
                    subTextFunction: item => item.stockConsignment.dueDate,
                    subTextFilter: 'toDayShortDate',
                });
                
                if (!this.isLengthyArray(rList)) {
                    return [];
                }
            }
            
            this.loadingMsg = null;

            //download logos
            var supplierIDs = rList.distinct(x => x.order.sellerID);
            
            for (let i = 0; i < supplierIDs.length; i++) {
                const supplierID = supplierIDs[i];
                try {
                    console.log(supplierID);
                    var imgData = await getImageData(this.companyLogoURL(supplierID));
                    if (imgData != null) {
                        rList.forEach(rItem => {
                            if (rItem.order.sellerID == supplierID) {
                                rItem.logoImgData = imgData;
                            }
                        })
                    }
                }
                catch (err) {
                    console.log('img err');
                    console.log(this.extractErrorDescription(err));
                }
            }

            var pages = [];

            rList.forEach(x => {
                var lines = this.copyDeep(x.order?.orderItems ?? x.stockConsignment?.consignmentOrderItems);
                if (this.isLengthyArray(lines)) {
                    var pageCount = Math.ceil(lines.length / 6);
                    var pageInd = 1;
                    var lineCount = 0;

                    do {
                        pages.push({
                            customer: x.order?.buyer ?? x.stockConsignment?.buyer,
                            destination: x.order?.location ?? x.stockConsignment?.destinationLocation,
                            dueDate: x.order?.dueDate ?? x.stockConsignment?.dueDate,
                            invoiceNumber: x.order?.customerOrderNumber,
                            lines: lines.slice(lineCount, lineCount + 6),
                            logoImgData: x.logoImgData,
                            supplier: x.order?.seller ?? x.stockConsignment?.seller,
                            pages: pageCount,
                            page: pageInd,
                            subTotal: x.order?.subTotal,
                            taxTotal: x.order?.taxTotal,
                        })
                        
                        lineCount += 6;
                        pageInd += 1;
                    } while (pageInd <= pageCount);
                }
            })

            var halfInd = Math.ceil(pages.length / 2);
            var sortedPages = [];

            for (let ii = 0; ii < halfInd; ii++) {
                const secondInd = halfInd + ii;
                sortedPages.push(pages[ii]);

                if (secondInd < pages.length) {
                    sortedPages.push(pages[secondInd]);
                }
            }

            return sortedPages;

            // return [...pages.slice(0, halfInd), ...pages.slice(halfInd + 1, pages.length - 1)];
        },
    }
}
</script>